/* ./src/index.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
*:focus,
*:hover {
  outline: none;
}
